angular.module('cpformplastApp')
  .controller('JobNotesModal', function ($scope, Util, $http, appConfig, Notification,DataManager, $modalInstance, subjob) {

    $scope.subjob = subjob;
    $scope.isDisabled = subjob.notes !== "" || $scope.isPreviewMode;
    $scope.isOriginalNoteEmtpy = subjob.notes === "";

    $scope.save = function() {
      $http.put(appConfig.api.url + '/api/jobs/' + subjob.job.id + '/subjobs/' + subjob.id, {notes: $scope.subjob.notes})
        .then(function(response) {
          $scope.$dismiss();
        }).catch(error => { Notification.error("Un problème est survenu")});
    };

    $scope.toggleTxtAreaDisabled = function () {$scope.isDisabled = !$scope.isDisabled}

  });
